@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@tailwind components;
@tailwind utilities;

.ReactModal__Content {
  opacity: 1;
  transform: translateX(1000px);
  transition: all 600ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateX(0px);
  transition: all 600ms ease-in-out;

}
