@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
/* purgecss start ignore */

.rdui-table .rdui-table-container{
  min-height:calc(100vh - 351px);
  overflow:auto
}

.rdui-table .rdui-table-container .table{
  margin-bottom:0
}

.rdui-table .header .th{
  background-color:var(--color-grey-600);
  color:var(--color-white-500);
  height:48px;
  padding:0 20px;
  border-right:1px solid var(--color-grey-700);
  font-size:16px;
  position:relative;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis
}

.rdui-table .header .th.sticky{
  position:-webkit-sticky !important;
  position:sticky !important;
  left:0;
  z-index:10
}

.rdui-table .header .th .resizer{
  display:inline-block;
  background:transparent;
  width:10px;
  height:100%;
  position:absolute;
  right:0;
  top:0;
  -webkit-transform:translateX(50%);
  transform:translateX(50%);
  z-index:1;
  touch-action:none
}

.rdui-table .header .th .resizer.isResizing{
  background:var(--color-grey-900)
}

.rdui-table .header .th:last-child{
  display:flex;
  justify-content:center;
  align-items:center
}

.rdui-table .header .th .rdui-table-header{
  display:flex;
  align-items:center;
  justify-content:space-between;
  height:100%
}

.rdui-table .header .th .rdui-table-header .title{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  max-width:200px
}

.rdui-table .header .th .rdui-table-header .sort{
  opacity:.5
}

.rdui-table .header .action{
  padding:0 10px
}

.rdui-table .header .action .rdui-table-header{
  justify-content:center
}

.rdui-table .body{
  border-top:none
}

.rdui-table .body .tr:hover .td{
  background-color:var(--color-golden-100)
}

.rdui-table .body .tr:nth-child(even) .td{
  background-color:var(--color-white-600)
}

.rdui-table .body .tr:nth-child(even):hover .td{
  background-color:var(--color-golden-100)
}

.rdui-table .body .td{
  padding:0 20px;
  height:54px;
  border-right:1px solid;
  border-bottom:1px solid;
  border-color:var(--color-grey-200);
  font-size:14px;
  color:var(--color-grey-600);
  font-weight:normal;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  background-color:var(--color-white-500);
  display:flex;
  align-items:center
}

.rdui-table .body .td:first-child{
  border-left:1px solid var(--color-grey-200)
}

.rdui-table .body .td:last-child{
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0
}

.rdui-table .body .td:last-child .scotty-button{
  margin-left:0
}

.rdui-table .body .td button:not(.scotty-label){
  margin-left:-9px;
  height:38px;
  padding:0 4px;
  display:inline-block
}

.rdui-table .body .td button:not(.scotty-label) .icon{
  font-size:24px
}

.rdui-table .body .td .roles{
  display:inline-grid;
  grid-auto-flow:column;
  grid-gap:4px;
  gap:4px
}

.rdui-table .pagination{
  display:flex;
  justify-content:space-between;
  height:45px;
  background-color:var(--color-white-500);
  align-items:center;
  padding:0 20px;
  position:relative;
  border-top:1px solid var(--color-grey-200)
}

.rdui-table .pagination .current-stats,.rdui-table .pagination .total-stats{
  color:var(--color-grey-500);
  font-weight:normal
}

.rdui-table .pagination .pages ul{
  display:flex;
  flex-direction:row;
  align-items:center
}

.rdui-table .pagination .pages ul .prev button,.rdui-table .pagination .pages ul .next button{
  background-color:transparent
}

.rdui-table .pagination .pages ul .prev button .icon,.rdui-table .pagination .pages ul .next button .icon{
  font-size:24px
}

.rdui-table .pagination .pages ul .pagination-container .pagination-item{
  font-size:14px;
  font-weight:normal;
  padding:0 14px;
  border-left:1px solid var(--color-grey-100);
  border-right:1px solid var(--color-grey-100);
  height:44px;
  display:flex;
  align-items:center;
  cursor:pointer
}

.rdui-table .pagination .pages ul .pagination-container .pagination-item.selected{
  color:var(--color-info-500)
}

.rdui-table .pagination .pages ul .pagination-container .pagination-item.dots{
  color:var(--color-grey-500)
}

/* purgecss end ignore */
.fixed{
  position: fixed;
}

.absolute{
  position: absolute;
}

.relative{
  position: relative;
}

.sticky{
  position: -webkit-sticky;
  position: sticky;
}

.top-0{
  top: 0px;
}

.right-4{
  right: 1rem;
}

.z-10{
  z-index: 10;
}

.z-40{
  z-index: 40;
}

.col-span-1{
  grid-column: span 1 / span 1;
}

.col-span-4{
  grid-column: span 4 / span 4;
}

.col-span-7{
  grid-column: span 7 / span 7;
}

.col-span-8{
  grid-column: span 8 / span 8;
}

.m-6{
  margin: 1.5rem;
}

.mt-2{
  margin-top: 0.5rem;
}

.mt-3{
  margin-top: 0.75rem;
}

.mt-4{
  margin-top: 1rem;
}

.mb-1{
  margin-bottom: 0.25rem;
}

.ml-6{
  margin-left: 1.5rem;
}

.ml-10{
  margin-left: 2.5rem;
}

.flex{
  display: flex;
}

.table{
  display: table;
}

.grid{
  display: grid;
}

.h-11{
  height: 2.75rem;
}

.h-56{
  height: 14rem;
}

.h-full{
  height: 100%;
}

.w-auto{
  width: auto;
}

.w-4\/12{
  width: 33.333333%;
}

.w-9\/12{
  width: 75%;
}

.w-full{
  width: 100%;
}

.flex-1{
  flex: 1 1 0%;
}

.transform{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse{
  50%{
    opacity: .5;
  }
}

@keyframes pulse{
  50%{
    opacity: .5;
  }
}

@-webkit-keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.cursor-pointer{
  cursor: pointer;
}

.grid-cols-12{
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.flex-row{
  flex-direction: row;
}

.flex-col{
  flex-direction: column;
}

.items-start{
  align-items: flex-start;
}

.items-center{
  align-items: center;
}

.justify-start{
  justify-content: flex-start;
}

.justify-center{
  justify-content: center;
}

.justify-between{
  justify-content: space-between;
}

.gap-1{
  gap: 0.25rem;
}

.gap-2{
  gap: 0.5rem;
}

.gap-3{
  gap: 0.75rem;
}

.gap-y-1{
  row-gap: 0.25rem;
}

.self-start{
  align-self: flex-start;
}

.overflow-y-hidden{
  overflow-y: hidden;
}

.border-2{
  border-width: 2px;
}

.border{
  border-width: 1px;
}

.border-gray-100{
  --tw-border-opacity: 1;
  border-color: rgba(244, 244, 245, var(--tw-border-opacity));
}

.border-grey-100{
  --tw-border-opacity: 1;
  border-color: rgba(247, 249, 252, var(--tw-border-opacity));
}

.border-grey-200{
  --tw-border-opacity: 1;
  border-color: rgba(237, 241, 247, var(--tw-border-opacity));
}

.bg-white-500{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-white-600{
  --tw-bg-opacity: 1;
  background-color: rgba(252, 253, 254, var(--tw-bg-opacity));
}

.bg-gray-600{
  --tw-bg-opacity: 1;
  background-color: rgba(82, 82, 91, var(--tw-bg-opacity));
}

.bg-success-100{
  --tw-bg-opacity: 1;
  background-color: rgba(215, 252, 221, var(--tw-bg-opacity));
}

.bg-danger-100{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 228, 218, var(--tw-bg-opacity));
}

.bg-opacity-60{
  --tw-bg-opacity: 0.6;
}

.p-5{
  padding: 1.25rem;
}

.p-6{
  padding: 1.5rem;
}

.pt-3{
  padding-top: 0.75rem;
}

.pt-4{
  padding-top: 1rem;
}

.pb-4{
  padding-bottom: 1rem;
}

.pl-1{
  padding-left: 0.25rem;
}

.pl-5{
  padding-left: 1.25rem;
}

.text-xs{
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base{
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg{
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-2xl{
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl{
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-5xl{
  font-size: 3rem;
  line-height: 1;
}

.font-normal{
  font-weight: 400;
}

.font-medium{
  font-weight: 500;
}

.capitalize{
  text-transform: capitalize;
}

.leading-5{
  line-height: 1.25rem;
}

.text-red-500{
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.text-success-500{
  --tw-text-opacity: 1;
  color: rgba(56, 202, 135, var(--tw-text-opacity));
}

.text-success-600{
  --tw-text-opacity: 1;
  color: rgba(40, 173, 126, var(--tw-text-opacity));
}

.text-info-500{
  --tw-text-opacity: 1;
  color: rgba(50, 156, 255, var(--tw-text-opacity));
}

.text-danger-500{
  --tw-text-opacity: 1;
  color: rgba(255, 71, 89, var(--tw-text-opacity));
}

.text-grey-200{
  --tw-text-opacity: 1;
  color: rgba(237, 241, 247, var(--tw-text-opacity));
}

.text-grey-400{
  --tw-text-opacity: 1;
  color: rgba(197, 206, 224, var(--tw-text-opacity));
}

.text-grey-500{
  --tw-text-opacity: 1;
  color: rgba(143, 155, 179, var(--tw-text-opacity));
}

.hover\:text-info-500:hover{
  --tw-text-opacity: 1;
  color: rgba(50, 156, 255, var(--tw-text-opacity));
}

.underline{
  text-decoration: underline;
}

*, ::before, ::after{
  --tw-shadow: 0 0 #0000;
}

*, ::before, ::after{
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.filter{
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.ReactModal__Content {
  opacity: 1;
  -webkit-transform: translateX(1000px);
  transform: translateX(1000px);
  transition: all 600ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  transition: all 600ms ease-in-out;
}

@media (min-width: 640px){
  .sm\:col-span-12{
    grid-column: span 12 / span 12;
  }

  .sm\:hidden{
    display: none;
  }

  .sm\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 768px){
  .md\:col-span-12{
    grid-column: span 12 / span 12;
  }

  .md\:hidden{
    display: none;
  }

  .md\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1024px){
  .lg\:col-span-12{
    grid-column: span 12 / span 12;
  }

  .lg\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1280px){
  .xl\:col-span-4{
    grid-column: span 4 / span 4;
  }

  .xl\:col-span-8{
    grid-column: span 8 / span 8;
  }

  .xl\:col-span-12{
    grid-column: span 12 / span 12;
  }

  .xl\:block{
    display: block;
  }

  .xl\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1536px){
}

