.rdui-table {
  .rdui-table-container {
    min-height: calc(100vh - 351px);
    overflow: auto;

    .table {
      margin-bottom: 0;
    }
  }

  .header {
    .th {
      background-color: var(--color-grey-600);
      color: var(--color-white-500);
      height: 48px;
      padding: 0 20px;
      border-right: 1px solid var(--color-grey-700);
      font-size: 16px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;


      &.sticky {
        position: sticky !important;
        left: 0;
        z-index: 10;
      }

      .resizer {
        display: inline-block;
        background: transparent;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        touch-action: none;

        &.isResizing {
          background: var(--color-grey-900);
        }
      }

      &:last-child{
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .rdui-table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }

        .sort {
          //position: absolute;
          //right: 13px;
          opacity: .5;
        }
      }
    }

    .action {
      padding: 0 10px;

      .rdui-table-header {
        justify-content: center;
      }
    }
  }

  .body {
    border-top: none;

    .tr {
      &:hover {
        .td {
          background-color: var(--color-golden-100);
        }
      }
    }

    .tr:nth-child(even) {
      .td {
        background-color: var(--color-white-600);
      }

      &:hover {
        .td {
          background-color: var(--color-golden-100);
        }
      }
    }

    .td {
      padding: 0 20px;
      height: 54px;
      border-right: 1px solid;
      border-bottom: 1px solid;
      border-color: var(--color-grey-200);
      font-size: 14px;
      color: var(--color-grey-600);
      font-weight: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: var(--color-white-500);
      display: flex;
      align-items: center;



      &:first-child {
        border-left: 1px solid var(--color-grey-200);
      }

      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        .scotty-button {
          margin-left: 0;
        }
      }

      button:not(.scotty-label) {
        margin-left: -9px;
        height: 38px;
        padding: 0 4px;
        display: inline-block;

        .icon {
          font-size: 24px;
        }
      }

      .roles {
        display: inline-grid;
        grid-auto-flow: column;
        gap: 4px;
      }
    }
  }


  .pagination {
    display: flex;
    justify-content: space-between;
    height: 45px;
    background-color: var(--color-white-500);
    align-items: center;
    padding: 0 20px;
    position: relative;
    border-top: 1px solid var(--color-grey-200);

    .current-stats,
    .total-stats {
      color: var(--color-grey-500);
      font-weight: normal;
    }

    .pages {
      ul {
        display: flex;
        flex-direction: row;
        align-items: center;

        .prev,
        .next {
          button {
            background-color: transparent;

            .icon {
              font-size: 24px;
            }
          }
        }

        .pagination-container {
          .pagination-item {
            font-size: 14px;
            font-weight: normal;
            padding: 0 14px;
            border-left: 1px solid var(--color-grey-100);
            border-right: 1px solid var(--color-grey-100);
            height: 44px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &.selected {
              color: var(--color-info-500);
            }

            &.dots {
              color: var(--color-grey-500);
            }
          }
        }
      }
    }
  }
}